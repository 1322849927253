import { render, staticRenderFns } from "./AddDemand2.vue?vue&type=template&id=56b0fccc&scoped=true&"
import script from "./AddDemand2.vue?vue&type=script&lang=js&"
export * from "./AddDemand2.vue?vue&type=script&lang=js&"
import style0 from "./AddDemand2.vue?vue&type=style&index=0&id=56b0fccc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56b0fccc",
  null
  
)

export default component.exports