<template>
  <div class="container">
    <div class="title">编辑信息</div>
    <div v-if="!hasLogin">请先进行登录！</div>
    <div v-else>
      <div class="layout-row">
        <div class="label">发布类型：</div>
        <RadioGroup v-model="releaseType" @on-change="onReleaseTypeChange">
          <Radio :key="0" :label="0" style="margin-right:20px;">供需</Radio>
          <Radio :key="1" :label="1" style="margin-right:20px;">公示</Radio>
        </RadioGroup>
      </div>
      <AddDemand v-show="releaseType === 0" v-model="showAddDemand" @on-success="onAddDemandSuccess"/>
      <PublicityEditor v-show="releaseType === 1" v-model="showAddPublicity" @on-success="onAddPublicitySuccess"/>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import AddDemand from '@/components/AddDemand2.vue'
import PublicityEditor from '@/components/PublicityEditor2.vue'

export default {
  name: 'EditInfo',
  components: {
    AddDemand,
    PublicityEditor
  },
  data() {
    return {
      releaseType: 0, // 0-demand 1-publicity
      showAddDemand: false,
      showAddPublicity: false
    }
  },
  computed: {
    ...mapGetters(['hasLogin', 'cityId'])
  },
  watch: {
    hasLogin(newVal) {
      if (newVal) {
        // this.getPublicityListData()
      } else {
        Object.assign(this.$data, this.$options.data.call(this))
      }
    }
  },
  mounted() {
    this.onReleaseTypeChange(0)
    this.showAddPublicity = true
    this.showAddDemand = true
  },
  methods: {
    ...mapMutations(['showLoginDialog']),
    onLogin() {
      this.showLoginDialog()
    },
    onReleaseTypeChange(e) {
      console.log(e)
    },
    onAddPublicitySuccess() {
      // this.getPublicityListData(0)
      this.$router.push({ path: '/user', query: { menuActive: '4' } })
    },
    onAddDemandSuccess() {
      // this.getDemandListData()
      this.$router.push({ path: '/user', query: { menuActive: '3' } })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  // padding: 20px 0 40px;
  padding: 20px;
  padding-bottom: 50px;
  background-color: #fff;
}

.title {
  font-size: 26px;
  font-weight: 700;
  padding-bottom: 20px;
}
.layout-row {
  margin-bottom: 15px;
  .label {
    width: 100px;
    text-align: right;
    margin-right: 5px;
    flex: 0 0 auto;
    &.required::before {
      content: '*';
      display: inline-block;
      margin-right: 4px;
      line-height: 1;
      font-family: SimSun;
      font-size: 14px;
      color: #ed4014;
    }
  }
}
</style>
